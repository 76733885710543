import React, { memo } from 'react';

import {
  STARLIFE_TOPIC_ID,
  STARS_TOPIC_ID,
} from 'common/routes/home/constants';
import {
  WidgetsColumnPropsType,
  renderWidgetsList,
} from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn';
import { WidgetsListBase } from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/components/Base';
import { WidgetsType } from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/typings';

const widgetsConfig = [
  { projectId: STARLIFE_TOPIC_ID, index: 0 },
  { projectId: STARLIFE_TOPIC_ID, index: 1 },
  { projectId: STARLIFE_TOPIC_ID, index: 2 },
  { projectId: STARS_TOPIC_ID, index: 0 },
  WidgetsType.WeekFilm,
  { projectId: STARS_TOPIC_ID, index: 1 },
  { projectId: STARS_TOPIC_ID, index: 2 },
];

export const KinoWidgetsHome = memo(({ puids }: WidgetsColumnPropsType) => (
  <WidgetsListBase puids={puids}>
    {renderWidgetsList(widgetsConfig)}
  </WidgetsListBase>
));
